<template>
    <div class="container">
        <div class="row">
            <div class="card">
                <h4 class="card-header">{{title}}
                    <span class="pull-right"><currency-switch></currency-switch></span>
                    <span class="pull-right" style="margin-right: 1rem;">
                        <input class="form-control" type="search" placeholder="search" v-model="searchQuery"
                               @change="search">
                    </span>
                </h4>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="list-group categories">
                                <template v-for="category in categories">
                                    <a v-if="category.children.length == 0 && category.parent_id == null" :class="'no-href-link list-group-item' +
                                   (category.id === selectedCategoryId ? ' active' : ' list-group-item-action') +
                                   (category.products.length === 0 ? ' disabled' : '')"
                                       @click="showByCategory(category)">
                                        {{category.title.toTitleCase("PHPA")}} ({{category.products.length}})
                                    </a>
                                    <template v-else-if="category.children.length >= 0 && category.parent_id == null">
                                        <a :href="'#group'+category.id" class="list-group-item list-group-item-action"
                                           data-toggle="collapse">{{category.title.toTitleCase("PHPA")}}
                                            ({{category.products.length}})<span class="pull-right"><i
                                                    class="fa fa-caret-down"></i></span></a>
                                        <div class="collapse" :id="'group'+category.id">
                                            <a v-for="child in category.children"
                                               @click="showByCategory(child)"
                                               :class="'no-href-link list-group-item' +
                                               (child.id === selectedCategoryId ? ' active' : ' list-group-item-action') +
                                               (child.products.length === 0 ? ' disabled' : '')"
                                               data-toggle="collapse">
                                                {{child.title.toTitleCase("PHPA")}} ({{child.products.length}})</a>
                                        </div>
                                    </template>
                                </template>
                            </div>

                        </div>
                        <div class="col-md-9">
                            <div class="row" v-if="!productView">
                                <store-product v-for="product in displayed_products"
                                               :product="product"
                                               :key="product.id"
                                               v-on:show="showProductView">
                                </store-product>
                            </div>
                            <div class="row" v-else>
                                <store-product-details v-on:close="closeProductView">
                                </store-product-details>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                productView: false,
                selectedCategoryId: null,
                displayed_products: [],
                searchQuery: null,
                title: "Featured",
                titleWas: "Featured"
            }
        },
        props: ['products', 'categories'],
        mounted() {
            $(window).on('popstate', (e) => {
                var state = e.originalEvent.state;
                if (state !== null) {
                    if (this.productView) {
                        this.closeProductView();
                    } else {
                        if ($.urlParam('product') != null) this.productView = true;
                        else if ($.urlParam('category') != null) {
                            for (let index in this.categories) {
                                if (this.categories[index].id == $.urlParam('category')) {
                                    this.showByCategory(this.categories[index]);
                                    break;
                                }
                            }
                        }
                    }
                }
            });
            if ($.urlParam('product') != null) {
                this.productView = true;
                this.displayed_products = this.products;
            }
            else if ($.urlParam('category') != null) {
                for (let index in this.categories) {
                    if (this.categories[index].id == $.urlParam('category')) {
                        this.showByCategory(this.categories[index]);
                        break;
                    }
                }
            } else {
                this.displayed_products = this.products;
            }
        },
        methods: {
            closeProductView() {
                this.productView = false;
                this.title = this.titleWas;
                window.history.pushState({}, document.title, "/store");
            },
            showProductView(product) {
                if (product.name) {
                    this.titleWas = this.title;
                    this.title = product.name.toTitleCase("PHPA");
                }
                window.history.pushState({}, document.title, "/store?product=" + product.id);
                this.productView = true;
            },
            showByCategory(category) {
                if (category.title) {
                    this.titleWas = this.title;
                    this.title = category.title.toTitleCase("PHPA");
                }
                window.history.pushState({}, document.title, "/store?category=" + category.id);
                this.displayed_products = category.products;
                this.productView = false;
            },
            search() {
                if (this.searchQuery != null && this.searchQuery !== '') {
                    window.axios.get('/product/name', {
                        params: {
                            name: this.searchQuery
                        }
                    }).then((response) => {
                        this.displayed_products = response.data;
                        this.productView = false;
                    }, (error) => {
                        console.log("product not found like " + this.searchQuery);
                    });
                } else {
                    this.displayed_products = this.products;
                    this.productView = false;
                }
            }
        },
        computed: {}
    }
</script>

<style scoped>
    a {
        color: #585858 !important;
    }

    .card {
        width: 100%;
    }

    .categories {
        margin-bottom: 1rem;
    }

    .list-group-item {
        padding: 0.3rem 0.6rem;
    }

    .form-control {
        padding: 0.187rem 0.37rem;
    }
</style>