<template>
    <div>
        <span class="currency-text">USD</span>
        <label class="switch">
            <input type="checkbox" v-model="currencySelection" @change="toggleCurrency">
            <span class="slider round"></span>
        </label>
        <span class="currency-text">CAD</span>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                currencySelection: true
            }
        },
        mounted() {
            if (this.$cookie.get('phpa_store_currency') == null) {
                this.$cookie.set('phpa_store_currency', 'USD', 365);
            }
            this.currencySelection = this.$cookie.get('phpa_store_currency') === 'CAD';
        },
        methods: {
            toggleCurrency() {
                if (this.$cookie.get('phpa_store_currency') === 'USD')
                    this.$cookie.set('phpa_store_currency', 'CAD', 365);
                else this.$cookie.set('phpa_store_currency', 'USD', 365);
                vuex.commit('UPDATE_CURRENCIES', null);
            }
        }
    }
</script>

<style scoped>
    /* The switch - the box around the slider */
    .switch {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 20px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        display: none;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 3px;
        left: 0;
        right: 0;
        bottom: -3px;
        background-color: #2081dc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: rgb(211, 45, 39);
    }

    input:focus + .slider {
        box-shadow: 0 0 1px rgb(211, 45, 39);
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 20px;
    }

    .slider.round:before {
        border-radius: 50%;
    }

    label {
        margin-bottom: 0;
    }

    .currency-text {
        font-size: 18px;
    }
</style>