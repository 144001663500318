
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');
window.Vuex = require('vuex');
var VueCookie = require('vue-cookie');

Vue.use(Vuex);
Vue.use(VueCookie);

require('./nav-bar');
require('./store.index');


// const app = new Vue({
//     el: '#app',
//     // store
// });