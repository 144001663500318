<template>
    <div v-if="product != null" class="card">
        <div class="card-block">
            <h4>{{product.name}} <a role="button" class="no-href-link pull-right" @click="$emit('close')"><i
                    class="fa fa-window-close"
                    aria-hidden="true"></i></a>
            </h4>
            <div class="row">
                <div class="col-md-4">
                    <p align="center">
                        <img v-if="product.images[0]" :src="'/storage/'+display_image.store_thumbnail"
                             :alt="product.name" data-toggle="modal" data-target=".image-carousel-modal"
                             class="pointer">
                        <br>
                        <small data-toggle="modal" data-target=".image-carousel-modal" class="pointer">(click to
                            expand)
                        </small>
                    </p>
                    <p align="center">
                        <img class="product-thumnail pointer" v-for="image in product.images"
                             :src="'/storage/'+image.product_thumbnail"
                             :alt="product.name" @click="display_image = image">
                    </p>
                </div>
                <div class="col-md-8">
                    <p class="card-text" v-html="product.description">
                    </p>
                </div>
            </div>

            <div class="row add-to-cart">
                <span class="price col-md-2 offset-md-3">{{getPrice()}}</span>
                <input class="col-md-2" type="number" v-model="quantity" min="1" :max="product.quantity"
                       :disabled="product.quantity <= 0">
                <button v-if="product.quantity > 0" type="button"
                        class="btn btn-primary btn-sm col-md-2 offset-md-1 pointer"
                        @click="addToCart" :disabled="waitingForServer">
                    {{waitingForServer ? "Please wait..." : "Add to Cart"}}
                </button>
                <button v-else type="button" class="btn btn-primary btn-sm col-md-2 offset-md-1"
                        disabled>Out of Stock
                </button>
            </div>

            <div class="modal fade image-carousel-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div id="productImageCarousel" class="carousel slide" data-ride="carousel"
                             data-interval="false">
                            <div class="carousel-inner" role="listbox">
                                <template v-for="image in product.images">
                                    <div :class="'carousel-item' + (display_image.id === image.id ? ' active' : '')">
                                        <img class="d-block img-fluid"
                                             :src="'/storage/'+image.file"
                                             :alt="product.name">
                                    </div>
                                </template>
                            </div>
                            <a class="carousel-control-prev" href="#productImageCarousel" role="button"
                               data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#productImageCarousel" role="button"
                               data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else style="width: 100%; padding-top: 5rem;">
        <vue-simple-spinner size="medium" message="Loading..."></vue-simple-spinner>
    </div>
</template>

<script>
    import Spinner from 'vue-simple-spinner';
    import Toasted from 'vue-toasted';
    import accounting from 'accounting';

    Vue.use(Toasted, {position: 'bottom-center', duration: 5000});
    export default {
        components: {
            Spinner
        },
        data() {
            return {
                product: null,
                quantity: 1,
                display_image: null,
                product_id: null,
                waitingForServer: false
            }
        },
        mounted() {
            this.product_id = $.urlParam('product');    //get product id from url
            window.axios.get('/product/' + this.product_id, {}).then((response) => {
                this.product = response.data.product;
                this.display_image = this.product.images[0];
                // find the featured image from the images array
                for (let index in this.product.images) {
                    if (this.product.images[index].featured) {
                        this.display_image = this.product.images[index];
                        break;
                    }
                }
                //if no images are marked as featured, default to image[0]
                if (this.display_image == null && this.product.images.length > 0) this.display_image = this.product.images[0];
            }, (error) => {
                console.log("product not found with id " + this.product_id);
                this.$emit('close');
            });
        },
        methods: {
            addToCart() {
                this.waitingForServer = true;
                window.axios.post('/cart/add', {
                    item_id: this.product.id,
                    quantity: this.quantity
                })
                    .then((response) => {
                        vuex.commit('UPDATE_CART', response.data.items);
                        this.waitingForServer = false;
                        Vue.toasted.show("Added " + this.quantity + " " + this.product.name + "(s) " + "to cart.");
                    }, (error) => {
                        this.waitingForServer = false;
                        for (let key in error.response.data.errors)
                            Vue.toasted.show(error.response.data.errors[key][0]);
                    });
            },
            getPrice() {
                return accounting.formatMoney(this.product.price * vuex.state.cart.currencyRatio);
            }
        }
    }
</script>

<style scoped>
    .card {
        width: 100%;
        padding: 1rem;
    }

    .product-thumnail {
        margin: 4px;
        width: 50px;
        height: 50px;
        border-style: solid;
        border-width: 2px;
    }

    .product-thumnail:hover {
        margin: 1px;
        width: 56px;
        height: 56px;
        border-style: none;
    }

    .card-text {
        max-height: 230px;
        overflow-y: scroll;
    }

    .modal-content {
        background-color: #2a2a2a;
    }

    .carousel-inner img {
        margin: auto;
    }
</style>