import { render, staticRenderFns } from "./CurrencySwitch.vue?vue&type=template&id=6ab5b837&scoped=true&"
import script from "./CurrencySwitch.vue?vue&type=script&lang=js&"
export * from "./CurrencySwitch.vue?vue&type=script&lang=js&"
import style0 from "./CurrencySwitch.vue?vue&type=style&index=0&id=6ab5b837&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ab5b837",
  null
  
)

export default component.exports