<template>
    <div class="card col-lg-4 col-md-6 col-sm-6">
        <p align="center">
            <img v-if="featured_image" :src="'/storage/'+featured_image.store_thumbnail" :alt="product.name"
                 @click="$emit('show', {id: product.id, name: product.name})" class="pointer">
        </p>
        <div class="card-block">
            <h4><a class="no-href-link" role="button" @click="$emit('show', {id: product.id, name: product.name})">{{product.name}}</a>
            </h4>
            <p class="card-text" v-html="product.description">
            </p>
            <div class="row add-to-cart">
                <h5 class="col-12">{{getPrice()}}</h5>
                <input class="form-control col-3 offset-1" v-model="quantity" type="number" min="1"
                       :max="product.quantity" :disabled="product.quantity <= 0">
                <button v-if="product.quantity > 0" type="button" class="btn btn-primary btn-sm col-5 offset-1 pointer"
                        @click="addToCart" :disabled="waitingForServer">
                    {{waitingForServer ? "Please wait..." : "Add to Cart"}}
                </button>
                <button v-else type="button" class="btn btn-primary btn-sm col-5 offset-1"
                        disabled>Out of Stock
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Toasted from 'vue-toasted';
    import accounting from 'accounting';

    Vue.use(Toasted, {position: 'bottom-center', duration: 5000});
    export default {
        data() {
            return {
                quantity: 1,
                featured_image: null,
                waitingForServer: false
            }
        },
        props: ['product'],
        mounted: function () {
            console.log('Product Helper Component mounted.');
            // find the featured image from the images array
            for (let index in this.product.images) {
                if (this.product.images[index].featured) {
                    this.featured_image = this.product.images[index];
                    break;
                }
            }
            //if no images are marked as featured, default to image[0]
            if (this.featured_image == null && this.product.images.length > 0) this.featured_image = this.product.images[0];
        },
        methods: {
            addToCart() {
                this.waitingForServer = true;
                window.axios.post('/cart/add', {
                    item_id: this.product.id,
                    quantity: this.quantity
                })
                    .then((response) => {
                        vuex.commit('UPDATE_CART', response.data.items);
                        this.waitingForServer = false;
                        Vue.toasted.show("Added " + this.quantity + " " + this.product.name + "(s) " + "to cart.");
                    }, (error) => {
                        for (let key in error.response.data.errors)
                            Vue.toasted.show(error.response.data.errors[key][0]);
                        this.waitingForServer = false;
                    });
            },
            getPrice() {
                return accounting.formatMoney(this.product.price * vuex.state.cart.currencyRatio);
            }
        }
    }
</script>

<style scoped>
    p {
        height: 150px;
        overflow-y: auto;
    }

    .card {
        padding: .7rem;
        padding-bottom: 5rem;
        border-radius: 0;
    }

    .add-to-cart {
        position: absolute;
        bottom: .5rem;
    }

    h4 {
        font-size: large;
        font-weight: bold;
        height: 3rem;
    }
</style>