<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-transparent mb-3 no-margin-bottom">
        <div class="container">
            <a class="navbar-brand primary-logo" href="/">
                <img class="phpa-logo" src="/images/PHPA_PRIMARY.png" alt="">
            </a>
<!--            <a href="#" class="meridian-logo hide-when-not-collapsed">-->
<!--                <img src="/images/meridianmainlogo.png" alt="">-->
<!--            </a>-->
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#app-navbar-collapse"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="app-navbar-collapse">
                <div class="log-in-nav hide-on-collapse">
<!--                    <a href="#" class="meridian-logo">-->
<!--                        <img src="/images/meridianmainlogo.png" alt="">-->
<!--                    </a>-->
                    <template v-if="vuex.getters.itemsInCart > 0">
                        <a href="/cart" class="btn btn-sm btn-light" role="button">
                            Cart <span class="badge badge-dark">{{vuex.getters.itemsInCart}}</span>
                            <span class="sr-only">items in cart</span>
                        </a>
                        <a href="/store" class="btn btn-sm btn-light" role="button">
                            <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                        </a>
                    </template>
                    <a v-else href="/store" class="btn btn-sm btn-light" role="button">
                        Shop <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                    </a>
                    <a href="https://www.facebook.com/pages/Professional-Hockey-Players-Association/173409159401617">
                        <i class="fa fa-facebook-square social-icon" aria-hidden="true"></i>
                    </a>
                    <a href="https://twitter.com/thephpa">
                      <i class="fa-brands fa-square-x-twitter social-icon" aria-hidden="true"></i>
                    </a>
                    <a href="https://www.instagram.com/thephpa/">
                        <i class="fa-brands fa-square-instagram social-icon" aria-hidden="true"></i>
                    </a>
                    <a href="https://www.youtube.com/channel/UCe-ciKfMG5wO9oHPz_tpieg">
                        <i class="fa-brands fa-youtube-square social-icon" aria-hidden="true"></i>
                    </a>
                    <a class="btn btn-sm btn-dark" href="/" role="button">Home</a>
                    <div v-if="vuex.getters.auth" class="btn-group">
                        <button type="button" class="btn btn-sm btn-dark dropdown-toggle pointer" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                            Welcome, {{vuex.getters.name}}
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="/my-account">My Account</a>
                            <a class="dropdown-item" href="/my-account/orders">My Orders</a>
                            <template v-if="vuex.getters.hasAdminRole">
                                <a v-if="vuex.getters.showViewAsGuestButton && !vuex.getters.viewAsGuest"
                                   class="dropdown-item" href="#" @click="vuex.commit('toggleViewAsGuest')">Disable
                                    Editing</a>
                                <a v-else-if="vuex.getters.showViewAsGuestButton && vuex.getters.viewAsGuest"
                                   class="dropdown-item" href="#" @click="vuex.commit('toggleViewAsGuest')">Enable
                                    Editing</a>
                                <a v-if="vuex.getters.showEditThisPageButton" class="dropdown-item"
                                   :href="'/page/edit'+getPathname()">Edit This Page</a>
                                <a class="dropdown-item" href="/admin">Admin Panel</a>
                            </template>
                            <button class="dropdown-item pointer" type="button" @click="logout">Logout</button>
                        </div>
                    </div>
                    <button v-else class="btn btn-sm btn-dark pointer"
                            data-toggle="modal" data-target="#loginModal">Login
                    </button>
                    <br>

                    <form class="d-none" id="logout-form" action="/logout" method="POST">
                        <input type="hidden" name="_token" :value="token">
                    </form>
                </div>

                <!-- Left Side Of Navbar -->
                <ul class="navbar-nav mr-auto">
                    &nbsp;
                </ul>

                <ul class="navbar-nav ml-auto mt-2 mt-lg-0 nav-bottom">

                    <!-- Authentication Links -->
                    <template v-for="item in menu">
                        <li v-if="item.submenu" class="nav-item dropdown">
                            <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" role="button"
                               aria-expanded="false">
                                {{item.title}}
                            </a>
                            <div class="dropdown-menu" role="menu">
                                <a v-for="subMenuItem in item.submenu" class="dropdown-item"
                                   :href="subMenuItem.link">{{subMenuItem.title}}</a>
                            </div>
                        </li>
                        <li v-else class="nav-item">
                            <a class="nav-link" :href="item.link">{{item.title}}</a>
                        </li>
                    </template>
                    <li class="nav-item hide-when-not-collapsed">
                        <a v-if="vuex.getters.itemsInCart > 0" class="nav-link" href="/cart">
                            Cart <span class="badge badge-dark">{{vuex.getters.itemsInCart}}</span>
                            <span class="sr-only">items in cart</span>
                        </a>
                        <a class="nav-link" href="/store">Shop <i class="fa fa-shopping-cart"
                                                                  aria-hidden="true"></i></a>
                    </li>

                    <li>
                        <div class="dropdown-divider"></div>
                    </li>

                    <li v-if="vuex.getters.auth" class="nav-item dropdown hide-when-not-collapsed">
                        <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" role="button"
                           aria-expanded="false">
                            Welcome, {{vuex.getters.name}}
                        </a>
                        <div class="dropdown-menu" role="menu">
                            <a class="dropdown-item" href="/my-account">My Account</a>
                            <a class="dropdown-item" href="/my-account/orders">My Orders</a>
                            <template v-if="vuex.getters.hasAdminRole">
                                <a v-if="vuex.getters.showViewAsGuestButton && !vuex.getters.viewAsGuest"
                                   class="dropdown-item" href="#" @click="vuex.commit('toggleViewAsGuest')">Disable
                                    Editing</a>
                                <a v-else-if="vuex.getters.showViewAsGuestButton && vuex.getters.viewAsGuest"
                                   class="dropdown-item" href="#" @click="vuex.commit('toggleViewAsGuest')">Enable
                                    Editing</a>
                                <a v-if="vuex.getters.showEditThisPageButton" class="dropdown-item"
                                   :href="'/page/edit'+getPathname()">Edit This Page</a>
                                <a class="dropdown-item" href="/admin">Admin Panel</a>
                            </template>
                            <a class="dropdown-item" href="#" @click="logout">Logout</a>
                        </div>
                    </li>
                    <li v-else class="nav-item hide-when-not-collapsed">
                        <form class="form-inline" method="POST" action="/login">
                            <input type="hidden" name="_token" :value="token">
                            <label class="sr-only" for="inlineFormInput">E-mail</label>
                            <input type="email" class="form-control mb-2 mr-sm-2 mb-sm-0" id="inlineFormInput"
                                   placeholder="email" name="email" required>

                            <label class="sr-only" for="inlineFormInputGroup">Password</label>
                            <div class="input-group mb-2 mr-sm-2 mb-sm-0">
                                <input type="password" class="form-control" id="inlineFormInputGroup"
                                       placeholder="password" name="password" required>
                            </div>

                            <div class="form-check mb-2 mr-sm-2 mb-sm-0">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="checkbox" name="remember"> Remember me
                                </label>
                            </div>

                            <button type="submit" class="btn btn-sm btn-dark w-100 py-2">Submit</button>
                        </form>
                    </li>
                    <li v-if="!vuex.getters.auth" class="nav-item hide-when-not-collapsed py-3">
                        <a href="/password/reset" class="nav-link btn btn-warning btn-sm">Forgot Your Password?</a>
                    </li>
                    <li v-if="!vuex.getters.auth" class="nav-item hide-when-not-collapsed">
                        <a class="w-100 btn btn-primary text-white" href="/register">
                            Create an Account
                        </a>
                    </li>
                    <li class="nav-item hide-when-not-collapsed">
                        <a class="nav-link">
                            <a href="https://www.facebook.com/pages/Professional-Hockey-Players-Association/173409159401617">
                                <i class="fa fa-facebook-square social-icon" aria-hidden="true"></i>
                            </a>
                            <a href="https://twitter.com/thephpa">
                                <i class="fa fa-twitter-square social-icon" aria-hidden="true"></i>
                            </a>
                            <a href="https://www.instagram.com/thephpa/">
                                <i class="fa fa-instagram social-icon" aria-hidden="true"></i>
                            </a>
                            <a href="https://www.youtube.com/channel/UCe-ciKfMG5wO9oHPz_tpieg">
                                <i class="fa fa-youtube-square social-icon" aria-hidden="true"></i>
                            </a>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <!--Login Modal-->
        <div class="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="loginModalTitle"
             aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="loginModalTitle">Login</h5>
                        <button type="button" class="close pointer" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-6">
                                <form method="POST" action="/login" class="form-horizontal">
                                    <input type="hidden" name="_token" :value="token">
                                    <div class="form-group"><label for="email"
                                                                   class="col-md-6 control-label">E-Mail
                                        Address</label>
                                        <input id="email" type="email" name="email" value=""
                                               required="required" autofocus="autofocus"
                                               class="form-control">
                                    </div>
                                    <div class="form-group"><label for="password"
                                                                   class="col-md-6 control-label">Password</label>
                                        <input id="password" type="password" name="password"
                                               required="required" class="form-control">
                                    </div>
                                    <div class="form-group">
                                        <div class="col-md-6 col-md-offset-4">
                                            <div class="checkbox"><label><input type="checkbox"
                                                                                name="remember">
                                                Remember Me
                                            </label></div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-outline-primary pointer mb-3">
                                            Login
                                        </button>
                                        <a href="/password/reset" class="btn btn-outline-warning mb-3">
                                            Forgot Your Password?
                                        </a>
                                        <a href="/register" class="btn btn-outline-info mb-3">
                                            Create an Account
                                        </a>
                                    </div>
                                </form>
                            </div>
                            <div class="col-md-6 hidden-sm">
                                <div class="row justify-content-center">
                                    <img src="/images/logo-2.png"/>
                                </div>
                                <div class="row justify-content-center">
                                    <h2>PHPA Secure Login</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    export default {
        data() {
            return {
                vuex: vuex, //current user based on vuex storage
                token: window.Laravel.csrfToken,
                showLoginModal: false,
            }
        },
        props: ['auth-user', 'menu', 'cart', 'currencies'],
        mounted() {
            console.log('Nav Bar Component mounted.');
            vuex.commit('setUser', this.authUser);   //set logged in user from session to vuex global user for children
            vuex.commit('UPDATE_CART', this.cart);   //set cart globally
            vuex.commit('UPDATE_CURRENCIES', this.currencies);
        },
        methods: {
            logout(event) {
                event.preventDefault();
                document.getElementById('logout-form').submit();
            },
            getPathname() {
                return window.location.pathname;
            }
        }
    }
</script>